import '../scss/style.scss';
import 'bootstrap/dist/js/bootstrap.js';
import slick from 'slick-carousel';

/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 * Copyright 2014-2017 The Bootstrap Authors
 * Copyright 2014-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// See the Getting Started docs for more information:
// https://getbootstrap.com/getting-started/#support-ie10-width
(function () {
  'use strict'
  if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style')
    msViewportStyle.appendChild(
      document.createTextNode(
        '@-ms-viewport{width:auto!important}'
      )
    )
    document.head.appendChild(msViewportStyle)
  }

  //custom
 
  //lazy load
  if ($(".hp-slider").length)
    $(".hp-slider").slick({
      lazyLoad: 'progressive',
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 1000,
      arrows: false,
      dots: true,
      fade: true,
      pauseOnFocus: false,
      pauseOnHover: false
    });
  if ($(".wedd-slider").length)
    $(".wedd-slider").slick({
      lazyLoad: 'progressive',
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 1000,
      arrows: false,
      dots: true,
      fade: true,
      pauseOnFocus: true,
      pauseOnHover: true
    });
  if ($(".wedd-slider").length)
    $('.wedd-slider').lightGallery({
        selector: 'a'
    });

}());

jQuery(window).on('load', function () {
  jQuery(".prev-next").click(function (n) { jQuery('html,body').animate({ scrollTop: jQuery("#dzs-tabs0").offset().top }, 500); });
});